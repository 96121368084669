import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import TopBar from "../components/TopBar";
import { useSelector } from "react-redux";

import Preloader from "../components/Preloader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const Profile = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [changesMade, setChangesMade] = useState(false);
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const user = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("mpi");

    const getUser = async () => {
      const { data } = await axios.get(
        "https://api.securedcompoundinterest.com/api/user",
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setName(data.name);
      setEmail(data.email);
      setPhone(data.phone);
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    };

    getUser();
  }, []);

  const editUser = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("mpi");

    try {
      setLoading(true);

      await axios.put(
        `https://api.securedcompoundinterest.com/api/user/${user?._id}`,
        {
          name,
          phone,
        },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      toast.success("Changes saved");
      setTimeout(() => {
        navigate(0);
      }, 1800);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const changePassword = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("mpi");

    if (newPassword.length < 6) {
      toast.error("Password should be at least 6 characters long");
      return;
    }

    if (newPassword !== confirmPassword) {
      setNewPassword("");
      setConfirmPassword("");
      toast.error("Passwords do not match");
      return;
    }

    try {
      setLoading(true);

      await axios.put(
        `https://api.securedcompoundinterest.com/api/auth/changepassword/${user?._id}`,
        { oldPassword, newPassword },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      toast.success("Password changed!");
      setTimeout(() => {
        navigate(0);
      }, 1800);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <div className="page-user">
      {pageLoading && <Preloader />}
      <TopBar />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="main-content col-lg-12">
              <div className="content-area card">
                <div className="card-innr">
                  <div className="card-head">
                    <h4 className="card-title">Profile Details</h4>
                  </div>
                  <ul className="nav nav-tabs nav-tabs-line" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#personal-data"
                      >
                        Personal Data
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#settings"
                      >
                        Settings
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#password"
                      >
                        Password
                      </a>
                    </li>
                  </ul>
                  {/* .nav-tabs-line */}
                  <div className="tab-content" id="profile-details">
                    <div
                      className="tab-pane fade show active"
                      id="personal-data"
                    >
                      <form onSubmit={editUser}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="input-item input-with-label">
                              <label
                                htmlFor="full-name"
                                className="input-item-label"
                              >
                                Full Name
                              </label>
                              <input
                                className="input-bordered"
                                type="text"
                                id="full-name"
                                name="full-name"
                                required
                                value={name}
                                onChange={(e) => {
                                  setName(e.target.value);
                                  setChangesMade(true);
                                }}
                              />
                            </div>
                            {/* .input-item */}
                          </div>
                          <div className="col-md-6">
                            <div className="input-item input-with-label">
                              <label
                                htmlFor="email-address"
                                className="input-item-label"
                              >
                                Email Address
                              </label>
                              <input
                                className="input-bordered"
                                type="text"
                                id="email-address"
                                name="email-address"
                                value={email}
                                disabled
                              />
                            </div>
                            {/* .input-item */}
                          </div>
                          <div className="col-md-6">
                            <div className="input-item input-with-label">
                              <label
                                htmlFor="mobile-number"
                                className="input-item-label"
                              >
                                Phone Number
                              </label>
                              <input
                                className="input-bordered"
                                type="text"
                                id="mobile-number"
                                name="mobile-number"
                                value={phone}
                                required
                                onChange={(e) => {
                                  setPhone(e.target.value);
                                  setChangesMade(true);
                                }}
                              />
                            </div>
                            {/* .input-item */}
                          </div>
                        </div>
                        {/* .row */}
                        <div className="gaps-1x" />
                        {/* 10px gap */}
                        {changesMade && (
                          <div className="d-sm-flex justify-content-between align-items-center">
                            {loading ? (
                              <button className="btn btn-primary" disabled>
                                Updating Profile...
                              </button>
                            ) : (
                              <button className="btn btn-primary">
                                Update Profile
                              </button>
                            )}
                          </div>
                        )}
                      </form>
                      {/* form */}
                    </div>
                    {/* .tab-pane */}
                    <div className="tab-pane fade" id="settings">
                      <div className="pdb-1-5x">
                        <h5 className="card-title card-title-sm text-dark">
                          Security Settings
                        </h5>
                      </div>
                      <div className="input-item">
                        <input
                          type="checkbox"
                          className="input-switch input-switch-sm"
                          id="save-log"
                          defaultChecked
                        />
                        <label htmlFor="save-log">Save my Activities Log</label>
                      </div>
                      <div className="input-item">
                        <input
                          type="checkbox"
                          className="input-switch input-switch-sm"
                          id="pass-change-confirm"
                        />
                        <label htmlFor="pass-change-confirm">
                          Confirm me through email before password change
                        </label>
                      </div>
                      <div className="pdb-1-5x">
                        <h5 className="card-title card-title-sm text-dark">
                          Manage Notification
                        </h5>
                      </div>
                      <div className="input-item">
                        <input
                          type="checkbox"
                          className="input-switch input-switch-sm"
                          id="latest-news"
                          defaultChecked
                        />
                        <label htmlFor="latest-news">
                          Notify me by email about sales and latest news
                        </label>
                      </div>
                      <div className="input-item">
                        <input
                          type="checkbox"
                          className="input-switch input-switch-sm"
                          id="activity-alert"
                          defaultChecked
                        />
                        <label htmlFor="activity-alert">
                          Alert me by email for unusual activity.
                        </label>
                      </div>
                      {/* <div className="gaps-1x" />
                      <div className="d-flex justify-content-between align-items-center">
                        <span />
                        <span className="text-success">
                          <em className="ti ti-check-box" /> Setting has been
                          updated
                        </span>
                      </div> */}
                    </div>
                    {/* .tab-pane */}
                    <div className="tab-pane fade" id="password">
                      <form onSubmit={changePassword}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="input-item input-with-label">
                              <label
                                htmlFor="old-pass"
                                className="input-item-label"
                              >
                                Current Password
                              </label>
                              <input
                                className="input-bordered"
                                type="password"
                                id="old-pass"
                                name="old-pass"
                                value={oldPassword}
                                required
                                onChange={(e) => setOldPassword(e.target.value)}
                              />
                            </div>
                            {/* .input-item */}
                          </div>
                          {/* .col */}
                        </div>
                        {/* .row */}
                        <div className="row">
                          <div className="col-md-6">
                            <div className="input-item input-with-label">
                              <label
                                htmlFor="new-pass"
                                className="input-item-label"
                              >
                                New Password
                              </label>
                              <input
                                className="input-bordered"
                                type="password"
                                id="new-pass"
                                name="new-pass"
                                value={newPassword}
                                required
                                onChange={(e) => setNewPassword(e.target.value)}
                              />
                            </div>
                            {/* .input-item */}
                          </div>
                          {/* .col */}
                          <div className="col-md-6">
                            <div className="input-item input-with-label">
                              <label
                                htmlFor="confirm-pass"
                                className="input-item-label"
                              >
                                Confirm New Password
                              </label>
                              <input
                                className="input-bordered"
                                type="password"
                                id="confirm-pass"
                                name="confirm-pass"
                                value={confirmPassword}
                                required
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                              />
                            </div>
                            {/* .input-item */}
                          </div>
                          {/* .col */}
                        </div>
                        {/* .row */}
                        <div className="note note-plane note-info pdb-1x">
                          <em className="fas fa-info-circle" />
                          <p>Password should be at least 6 characters long.</p>
                        </div>
                        <div className="gaps-1x" />
                        {/* 10px gap */}
                        <div className="d-sm-flex justify-content-between align-items-center">
                          {loading ? (
                            <button className="btn btn-primary" disabled>
                              Updating...
                            </button>
                          ) : (
                            <button className="btn btn-primary">Update</button>
                          )}
                          {/* <div className="gaps-2x d-sm-none" />
                        <span className="text-success">
                          <em className="ti ti-check-box" /> Changed Password
                        </span> */}
                        </div>
                      </form>
                    </div>
                    {/* .tab-pane */}
                  </div>
                  {/* .tab-content */}
                </div>
                {/* .card-innr */}
              </div>
            </div>
          </div>
          {/* .container */}
        </div>
        {/* .container */}
      </div>

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />
    </div>
  );
};

export default Profile;
