import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import FeatherIcon from "feather-icons-react";

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  const registerHandler = async (e) => {
    e.preventDefault();

    if (password.length < 6) {
      toast.error("Password should be at least 6 characters long");
      return;
    }

    if (password !== confirmPassword) {
      setPassword("");
      setConfirmPassword("");
      toast.error("Passwords do not match");
      return;
    }

    try {
      setLoading(true);

      const { data } = await axios.post(
        "https://api.securedcompoundinterest.com/api/auth/register",
        { name, email: email.toLowerCase(), phone, password }
      );

      setLoading(false);
      if (data.message === "Verification email sent") {
        navigate("/signup-success");
      } else {
        toast.error("Something went wrong");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <div className="page-ath">
      <div className="page-ath-wrap">
        <div className="page-ath-content">
          <div className="page-ath-header">
            <Link to="/" className="page-ath-logo">
              <img src={logo} alt="logo" style={{ width: "220px" }} />
            </Link>
          </div>
          <div className="page-ath-form">
            <h2 className="page-ath-heading">
              Sign up <small>Create New Account</small>
            </h2>
            <form onSubmit={registerHandler}>
              <div className="input-item">
                <input
                  type="text"
                  placeholder="Your Name"
                  className="input-bordered"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="input-item">
                <input
                  type="email"
                  placeholder="Your Email"
                  className="input-bordered"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="input-item">
                <PhoneInput
                  className="input-bordered"
                  placeholder="Phone Number"
                  required
                  value={phone}
                  onChange={(number) => setPhone(number)}
                />
              </div>
              <div className="input-item" style={{ position: "relative" }}>
                <input
                  type={`${passwordShown ? "text" : "password"}`}
                  placeholder="Password"
                  className="input-bordered"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <a
                  href="#/"
                  onClick={togglePasswordVisibility}
                  style={{ position: "absolute", right: "10px", top: "8px" }}
                >
                  <FeatherIcon
                    icon={`${passwordShown ? "eye-off" : "eye"}`}
                    size="1em"
                  />
                </a>
              </div>
              <div className="input-item" style={{ position: "relative" }}>
                <input
                  type={`${confirmPasswordShown ? "text" : "password"}`}
                  placeholder="Repeat Password"
                  className="input-bordered"
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <a
                  href="#/"
                  onClick={toggleConfirmPasswordVisibility}
                  style={{ position: "absolute", right: "10px", top: "8px" }}
                >
                  <FeatherIcon
                    icon={`${confirmPasswordShown ? "eye-off" : "eye"}`}
                    size="1em"
                  />
                </a>
              </div>

              {loading ? (
                <button
                  className="btn btn-primary btn-block"
                  type="button"
                  disabled
                >
                  Creating Account...
                </button>
              ) : (
                <button className="btn btn-primary btn-block" type="submit">
                  Create Account
                </button>
              )}
            </form>

            <div className="gaps-2x" />
            <div className="gaps-2x" />
            <div className="form-note">
              Already have an account ?{" "}
              <Link to="/login">
                {" "}
                <strong>Sign in</strong>
              </Link>
            </div>
          </div>
          <div className="page-ath-footer">
            <ul className="footer-links">
              <li>© {new Date().getFullYear()} MPI Unlimited.</li>
            </ul>
          </div>
        </div>
        <div className="page-ath-gfx">
          <div className="w-100 d-flex justify-content-center">
            <div className="col-md-8 col-xl-5" />
          </div>
        </div>
      </div>

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />
    </div>
  );
};

export default Register;
