import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import NotFound from "./pages/NotFound";
import Activity from "./pages/Activity";
import Register from "./pages/auth/Register";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  loadUser,
  loadingUser,
  logoutUser,
  noUser,
} from "./store/redux/userRedux";
import Login from "./pages/auth/Login";
import EmailSent from "./pages/auth/EmailSent";
import EmailVerified from "./pages/auth/EmailVerified";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import Transactions from "./pages/Transactions";
import Profile from "./pages/Profile";
import AdminTransactionDetails from "./pages/admin/AdminTransactionDetails";
import AdminTransactions from "./pages/admin/AdminTransactions";
import PaymentDetails from "./pages/admin/PaymentDetails";
import Users from "./pages/admin/Users";
import UserDetails from "./pages/admin/UserDetails";
import Deposit from "./pages/Deposit";
import Withdraw from "./pages/Withdraw";

const App = () => {
  const user = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadingUser());
    const token = localStorage.getItem("mpi");

    const getUser = async () => {
      try {
        const { data } = await axios.get(
          "https://api.securedcompoundinterest.com/api/user",
          {
            headers: { token: `Bearer ${token}` },
          }
        );

        dispatch(loadUser(data));
      } catch (error) {
        if (error.response) {
          if (error.response.data === "Token is not valid!") {
            localStorage.removeItem("mpi");
            dispatch(logoutUser());
          }
        }
      }
    };

    if (token) {
      getUser();
    } else {
      dispatch(noUser());
    }
  }, [dispatch]);

  return (
    <Routes>
      <Route path="/" element={user ? <Dashboard /> : <Login />} />
      <Route
        path="/activity"
        element={user ? <Activity /> : <Navigate replace to={"/login"} />}
      />
      <Route
        path="/admin-transactions"
        element={
          user && user?.isAdmin ? (
            <AdminTransactions />
          ) : (
            <Navigate replace to={"/login"} />
          )
        }
      />
      <Route
        path="/admin-transaction/:id"
        element={
          user && user?.isAdmin ? (
            <AdminTransactionDetails />
          ) : (
            <Navigate replace to={"/login"} />
          )
        }
      />
      <Route
        path="/deposit"
        element={user ? <Deposit /> : <Navigate replace to={"/login"} />}
      />
      <Route
        path="/forgot-password"
        element={!user ? <ForgotPassword /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/login"
        element={!user ? <Login /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/passwordreset/:resetToken"
        element={!user ? <ResetPassword /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/payment-details"
        element={
          user && user?.isAdmin ? (
            <PaymentDetails />
          ) : (
            <Navigate replace to={"/login"} />
          )
        }
      />
      <Route
        path="/profile"
        element={user ? <Profile /> : <Navigate replace to={"/login"} />}
      />
      <Route
        path="/register"
        element={!user ? <Register /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/transactions"
        element={user ? <Transactions /> : <Navigate replace to={"/login"} />}
      />
      <Route
        path="/signup-success"
        element={!user ? <EmailSent /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/users"
        element={
          user && user?.isAdmin ? <Users /> : <Navigate replace to={"/login"} />
        }
      />
      <Route
        path="/user/:id"
        element={
          user && user?.isAdmin ? (
            <UserDetails />
          ) : (
            <Navigate replace to={"/login"} />
          )
        }
      />
      <Route
        path="/verified"
        element={!user ? <EmailVerified /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/withdraw"
        element={user ? <Withdraw /> : <Navigate replace to={"/login"} />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
