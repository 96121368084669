// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAiTTxT5YUeKQwzEPQRTJGBgJ0N2UdaZI8",
  authDomain: "mpi-fc465.firebaseapp.com",
  projectId: "mpi-fc465",
  storageBucket: "mpi-fc465.appspot.com",
  messagingSenderId: "360462496995",
  appId: "1:360462496995:web:0209105c10dd57d32c01d6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
