import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import FeatherIcon from "feather-icons-react";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);

  const navigate = useNavigate();
  const { resetToken } = useParams();

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const resetPassword = async (e) => {
    e.preventDefault();

    if (password.length < 6) {
      toast.error("Password should be at least 6 characters long");
      return;
    }

    if (password !== confirmPassword) {
      setPassword("");
      setConfirmPassword("");
      toast.error("Passwords do not match");
      return;
    }

    try {
      setLoading(true);

      await axios.put(
        `https://api.securedcompoundinterest.com/api/auth/passwordreset/${resetToken}`,
        { password }
      );

      setLoading(false);
      toast.success("Password reset successfully!");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="page-ath">
      <div className="page-ath-wrap">
        <div className="page-ath-content">
          <div className="page-ath-header">
            <Link to="/" className="page-ath-logo">
              <img src={logo} alt="logo" style={{ width: "220px" }} />
            </Link>
          </div>
          <div className="page-ath-form">
            <h2 className="page-ath-heading">Reset password</h2>
            <form onSubmit={resetPassword}>
              <div className="input-item" style={{ position: "relative" }}>
                <input
                  type={`${passwordShown ? "text" : "password"}`}
                  placeholder="New Password"
                  className="input-bordered"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <a
                  href="#/"
                  onClick={togglePasswordVisibility}
                  style={{ position: "absolute", right: "10px", top: "8px" }}
                >
                  <FeatherIcon
                    icon={`${passwordShown ? "eye-off" : "eye"}`}
                    size="1em"
                  />
                </a>
              </div>
              <div className="input-item" style={{ position: "relative" }}>
                <input
                  type={`${passwordShown ? "text" : "password"}`}
                  placeholder="Confirm Password"
                  className="input-bordered"
                  required
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                />
                <a
                  href="#/"
                  onClick={togglePasswordVisibility}
                  style={{ position: "absolute", right: "10px", top: "8px" }}
                >
                  <FeatherIcon
                    icon={`${passwordShown ? "eye-off" : "eye"}`}
                    size="1em"
                  />
                </a>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  {loading ? (
                    <button
                      className="btn btn-primary btn-block"
                      type="button"
                      disabled
                    >
                      Resetting...
                    </button>
                  ) : (
                    <button className="btn btn-primary btn-block" type="submit">
                      Reset
                    </button>
                  )}
                </div>
              </div>
              <div className="gaps-2x" />
            </form>
          </div>
          <div className="page-ath-footer">
            <ul className="footer-links">
              <li>© {new Date().getFullYear()} MPI Unlimited.</li>
            </ul>
          </div>
        </div>
        <div className="page-ath-gfx">
          <div className="w-100 d-flex justify-content-center">
            <div className="col-md-8 col-xl-5" />
          </div>
        </div>
      </div>

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />
    </div>
  );
};

export default ResetPassword;
