import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import logo from "../../assets/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loginUser } from "../../store/redux/userRedux";
import axios from "axios";
import { browserName, osName } from "react-device-detect";
import FeatherIcon from "feather-icons-react";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [ip, setIp] = useState("");
  const [browser, setBrowser] = useState("");
  const [os, setOs] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    setBrowser(browserName);
    setOs(osName);

    const getData = async () => {
      const { data } = await axios.get("https://geolocation-db.com/json/");
      setIp(data.IPv4);
    };

    getData();
  }, []);

  const loginHandler = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const { data } = await axios.post(
        "https://api.securedcompoundinterest.com/api/auth/login",
        {
          email,
          password,
        }
      );

      await axios.post("https://api.securedcompoundinterest.com/api/activity", {
        ip,
        browser,
        os,
        userId: data.others._id,
      });

      setLoading(false);
      dispatch(loginUser(data));
      localStorage.setItem("mpi", data.token);
      navigate("/");
    } catch (error) {
      setLoading(false);
      if (error.response) {
        if (error.response.data.error === "Account disabled") {
          toast.error("Account disabled. Please contact support.");
        } else {
          toast.error(error.response.data.error);
        }
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <div className="page-ath">
      <div className="page-ath-wrap">
        <div className="page-ath-content">
          <div className="page-ath-header">
            <Link to="/" className="page-ath-logo">
              <img src={logo} alt="logo" style={{ width: "220px" }} />
            </Link>
          </div>
          <div className="page-ath-form">
            <h2 className="page-ath-heading">
              Sign in <small>to your account</small>
            </h2>
            <form onSubmit={loginHandler}>
              <div className="input-item">
                <input
                  type="text"
                  placeholder="Your Email"
                  className="input-bordered"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="input-item" style={{ position: "relative" }}>
                <input
                  type={`${passwordShown ? "text" : "password"}`}
                  placeholder="Password"
                  className="input-bordered"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <a
                  href="#/"
                  onClick={togglePasswordVisibility}
                  style={{ position: "absolute", right: "10px", top: "8px" }}
                >
                  <FeatherIcon
                    icon={`${passwordShown ? "eye-off" : "eye"}`}
                    size="1em"
                  />
                </a>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <Link to="/forgot-password">Forgot password?</Link>
                  <div className="gaps-2x" />
                </div>
              </div>
              {loading ? (
                <button
                  className="btn btn-primary btn-block"
                  type="button"
                  disabled
                >
                  Signing In...
                </button>
              ) : (
                <button className="btn btn-primary btn-block" type="submit">
                  Sign In
                </button>
              )}
            </form>

            <div className="gaps-2x" />
            <div className="gaps-2x" />
            <div className="form-note">
              Don’t have an account?{" "}
              <Link to="/register">
                <strong>Sign up</strong>
              </Link>
            </div>
          </div>
          <div className="page-ath-footer">
            <ul className="footer-links">
              <li>© {new Date().getFullYear()} MPI Unlimited.</li>
            </ul>
          </div>
        </div>
        <div className="page-ath-gfx">
          <div className="w-100 d-flex justify-content-center">
            <div className="col-md-8 col-xl-5" />
          </div>
        </div>
      </div>

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />
    </div>
  );
};

export default Login;
