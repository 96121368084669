import { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import TopBar from "../../components/TopBar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Preloader from "../../components/Preloader";

const UserDetails = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [user, setUser] = useState({});
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [suspendModalOpen, setSuspendModalOpen] = useState(false);
  const [unsuspendModalOpen, setUnsuspendModalOpen] = useState(false);
  const [verifyModalOpen, setVerifyModalOpen] = useState(false);
  const [revenueInputModalOpen, setRevenueInputModalOpen] = useState(false);
  const [revenueModalOpen, setRevenueModalOpen] = useState(false);
  const [revenue, setRevenue] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();

  const toggleOptions = () => {
    setOptionsOpen((prevState) => !prevState);
  };

  const suspendUser = async () => {
    const token = localStorage.getItem("mpi");
    setLoading(true);

    try {
      await axios.put(
        "https://api.securedcompoundinterest.com/api/user/suspend",
        { idToSuspend: id },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setSuspendModalOpen(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const unsuspendUser = async () => {
    const token = localStorage.getItem("mpi");
    setLoading(true);

    try {
      await axios.put(
        "https://api.securedcompoundinterest.com/api/user/unsuspend",
        { idToUnsuspend: id },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setUnsuspendModalOpen(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const verifyEmail = async () => {
    const token = localStorage.getItem("mpi");
    setLoading(true);

    try {
      await axios.put(
        `https://api.securedcompoundinterest.com/api/user/verify/${id}`,
        { verified: true },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const addRevenue = async () => {
    const token = localStorage.getItem("mpi");
    setLoading(true);

    try {
      await axios.post(
        "https://api.securedcompoundinterest.com/api/user/revenue",
        {
          revenue: +revenue,
          user,
        },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setRevenueModalOpen(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("mpi");

    const getUserDetails = async () => {
      const { data } = await axios.get(
        `https://api.securedcompoundinterest.com/api/user/${id}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setUser(data);
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    };

    getUserDetails();
  }, [id]);

  return (
    <div className="page-user">
      {pageLoading && <Preloader />}
      <TopBar />
      <div className="page-content">
        <div className="container">
          <div className="card content-area">
            <div className="card-innr card-innr-fix">
              <div className="card-head d-flex justify-content-between align-items-center">
                <h4 className="card-title mb-0">User Details</h4>
                <div className="d-flex align-items-center guttar-20px">
                  <div className="flex-col d-sm-block d-none">
                    <Link
                      to="/users"
                      className="btn btn-sm btn-auto btn-primary"
                    >
                      <em className="fas fa-arrow-left mr-3" />
                      Back
                    </Link>
                  </div>
                  <div className="flex-col d-sm-none">
                    <Link
                      to="/users"
                      className="btn btn-icon btn-sm btn-primary"
                    >
                      <em className="fas fa-arrow-left" />
                    </Link>
                  </div>
                  <div className="relative d-inline-block">
                    <button
                      onClick={toggleOptions}
                      className="btn btn-dark btn-sm btn-icon toggle-tigger"
                    >
                      <em className="ti ti-more-alt" />
                    </button>
                    {optionsOpen && (
                      <div className="toggle-class dropdown-content dropdown-content-top-left active">
                        <ul className="dropdown-list">
                          {!user.verified && (
                            <li>
                              <a
                                href="#/"
                                onClick={() => {
                                  setVerifyModalOpen(true);
                                }}
                              >
                                Verify Email
                              </a>
                            </li>
                          )}
                          {user.disabled ? (
                            <li>
                              <a
                                href="#/"
                                onClick={() => {
                                  setUnsuspendModalOpen(true);
                                }}
                              >
                                <em className="ti ti-na" /> Unsuspend
                              </a>
                            </li>
                          ) : (
                            <li>
                              <a
                                href="#/"
                                onClick={() => {
                                  setSuspendModalOpen(true);
                                }}
                              >
                                <em className="ti ti-na" /> Suspend
                              </a>
                            </li>
                          )}
                          <li>
                            <a
                              href="#/"
                              onClick={() => setRevenueInputModalOpen(true)}
                            >
                              <em className="fas fa-shield-alt" /> Add Revenue
                            </a>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="gaps-1-5x" />
              <div className="data-details d-md-flex">
                <div className="fake-class">
                  <span className="data-details-title">Total Deposit</span>
                  <span className="data-details-info large">
                    ${user?.deposit?.toLocaleString()}
                  </span>
                </div>
                <div className="fake-class">
                  <span className="data-details-title">Total Revenue</span>
                  <span className="data-details-info large">
                    ${user?.revenue?.toLocaleString()}
                  </span>
                </div>
                <div className="fake-class">
                  <span className="data-details-title">Balance</span>
                  <span className="data-details-info large">
                    ${user?.balance?.toLocaleString()}
                  </span>
                </div>

                <ul className="data-vr-list">
                  {user.verified ? (
                    <li>
                      <div className="data-state data-state-sm data-state-approved" />{" "}
                      Email
                    </li>
                  ) : (
                    <li>
                      <div className="data-state data-state-sm data-state-pending" />{" "}
                      Email
                    </li>
                  )}
                  {user.kycStatus === "Approved" && (
                    <li>
                      <div className="data-state data-state-sm data-state-approved" />{" "}
                      KYC
                    </li>
                  )}
                  {user.kycStatus === "Rejected" && (
                    <li>
                      <div className="data-state data-state-sm data-state-canceled" />{" "}
                      KYC
                    </li>
                  )}
                  {(user.kycStatus === "Unverified" ||
                    user.kycStatus === "Pending") && (
                    <li>
                      <div className="data-state data-state-sm data-state-pending" />{" "}
                      KYC
                    </li>
                  )}
                </ul>
              </div>
              <div className="gaps-3x" />
              <h6 className="card-sub-title">User Information</h6>
              <ul className="data-details-list">
                <li>
                  <div className="data-details-head">Full Name</div>
                  <div className="data-details-des">{user?.name}</div>
                </li>
                <li>
                  <div className="data-details-head">Email Address</div>
                  <div className="data-details-des">{user?.email}</div>
                </li>
                {/* li */}
                <li>
                  <div className="data-details-head">Phone Number</div>
                  <div className="data-details-des">{user?.phone}</div>
                </li>
              </ul>
              <div className="gaps-3x" />
              <h6 className="card-sub-title">More Information</h6>
              <ul className="data-details-list">
                <li>
                  <div className="data-details-head">Joining Date</div>
                  <div className="data-details-des">
                    {new Date(user?.createdAt).toLocaleString()}
                  </div>
                </li>
                {/* li */}
                <li>
                  <div className="data-details-head">Reg Method</div>
                  <div className="data-details-des">Email</div>
                </li>
                {user?.disabled ? (
                  <li>
                    <div className="data-details-head">Status</div>
                    <div className="data-details-des">Suspended</div>
                  </li>
                ) : (
                  <li>
                    <div className="data-details-head">Status</div>
                    <div className="data-details-des">Active</div>
                  </li>
                )}
              </ul>
            </div>
            {/* .card-innr */}
          </div>
          {/* .card */}
        </div>
        {/* .container */}
      </div>

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />

      {suspendModalOpen && <div className="modal-backdrop fade show" />}
      {suspendModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                style={{ border: "none", background: "transparent" }}
                onClick={() => setSuspendModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to suspend this user?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Suspending...
                  </button>
                ) : (
                  <button onClick={suspendUser} className="btn btn-primary">
                    Yes, Suspend
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}

      {unsuspendModalOpen && <div className="modal-backdrop fade show" />}
      {unsuspendModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                style={{ border: "none", background: "transparent" }}
                onClick={() => setUnsuspendModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to unsuspend this user?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Unsuspending...
                  </button>
                ) : (
                  <button onClick={unsuspendUser} className="btn btn-primary">
                    Yes, Unsuspend
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}

      {verifyModalOpen && <div className="modal-backdrop fade show" />}
      {verifyModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                style={{ border: "none", background: "transparent" }}
                onClick={() => setVerifyModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to verify this user?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Verifying...
                  </button>
                ) : (
                  <button onClick={verifyEmail} className="btn btn-primary">
                    Yes, Verify
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}

      {revenueInputModalOpen && <div className="modal-backdrop fade show" />}
      {revenueInputModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                onClick={() => setRevenueInputModalOpen(false)}
                className="modal-close"
                style={{ background: "none", border: "transparent" }}
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">Input amount(USD)</h4>
                <div className="gaps-2x" />
                <input
                  className="input-bordered mb-4"
                  type="number"
                  value={revenue}
                  onChange={(e) => {
                    setRevenue(e.target.value);
                  }}
                />
                <button
                  onClick={() => {
                    setRevenueInputModalOpen(false);
                    setRevenueModalOpen(true);
                  }}
                  className="btn btn-primary"
                >
                  Confirm
                </button>
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}

      {revenueModalOpen && <div className="modal-backdrop fade show" />}
      {revenueModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                onClick={() => setRevenueModalOpen(false)}
                className="modal-close"
                style={{ background: "none", border: "transparent" }}
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to add ${revenue} this user?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Updating...
                  </button>
                ) : (
                  <button onClick={addRevenue} className="btn btn-primary">
                    Yes, Update
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDetails;
