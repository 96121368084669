import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TopBar from "../components/TopBar";
import axios from "axios";

const Withdraw = () => {
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [formCompleted, setFormCompleted] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [cryptoAddress, setCryptoAddress] = useState("");

  const user = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (+amount && +amount <= user?.balance && cryptoAddress) {
      setFormCompleted(true);
    } else {
      setFormCompleted(false);
    }
  }, [amount, cryptoAddress, user?.balance]);

  const withdraw = async () => {
    const token = localStorage.getItem("mpi");
    setLoading(true);

    try {
      await axios.post(
        `https://api.securedcompoundinterest.com/api/transaction/${user?._id}`,
        {
          user,
          amount: +amount,
          transactionType: "Withdrawal",
          usdcAddress: cryptoAddress,
          transactionId: new Date().getTime().toString(),
          dateTime: new Date(),
          cryptoAddress,
          remarks,
        },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setReviewModalOpen(true);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="page-user">
      <TopBar />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="main-content col-lg-12">
              <div className="content-area card">
                <div className="card-innr">
                  <div className="card-head">
                    <span className="card-sub-title text-primary font-mid">
                      Step 1
                    </span>
                    <h4 className="card-title">Enter withdrawal amount</h4>
                  </div>
                  <div className="card-text">
                    <p>Enter amount to be paid into your USDC wallet</p>
                  </div>
                  <div className="token-contribute">
                    <div className="token-calc">
                      <div className="token-pay-amount">
                        <input
                          id="token-base-amount"
                          className="input-bordered"
                          type="tel"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="pay-notes" />

                  <div className="card-head">
                    <span className="card-sub-title text-primary font-mid">
                      Step 3
                    </span>
                    <h4 className="card-title">Enter wallet address</h4>
                  </div>
                  <div className="card-text">
                    <p>Simply paste your USDC wallet address</p>
                  </div>

                  <div className="input-item input-with-label mt-3">
                    <label htmlFor="address" className="input-item-label">
                      Wallet address:
                    </label>
                    <textarea
                      className="input-bordered"
                      id="address"
                      rows={1}
                      value={cryptoAddress}
                      onChange={(e) => {
                        setCryptoAddress(e.target.value);
                      }}
                    />
                  </div>
                  <div className="input-item input-with-label">
                    <label htmlFor="remarks" className="input-item-label">
                      Additional info(Optional):
                    </label>
                    <textarea
                      className="input-bordered"
                      id="remarks"
                      rows={1}
                      value={remarks}
                      onChange={(e) => {
                        setRemarks(e.target.value);
                      }}
                    />
                  </div>
                  {formCompleted && (
                    <>
                      {loading ? (
                        <button className="btn btn-primary" disabled>
                          Confirming Payment...
                        </button>
                      ) : (
                        <button className="btn btn-primary" onClick={withdraw}>
                          Confirm Payment
                        </button>
                      )}
                    </>
                  )}
                </div>{" "}
              </div>{" "}
            </div>
          </div>
          {/* .container */}
        </div>
        {/* .container */}
      </div>

      {reviewModalOpen && <div className="modal-backdrop fade show" />}
      {reviewModalOpen && (
        <div
          className="modal fade show"
          id="pay-review"
          tabIndex={-1}
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="pay-status pay-status-success">
                  <em className="ti ti-check" />
                </div>
                <div className="gaps-2x" />
                <h3>We’re reviewing your request</h3>
                <p>
                  We’ll review your request and get back to you as soon as
                  possible.
                </p>
                <div className="gaps-2x" />
                <button
                  className="btn btn-primary bg-green"
                  type="button"
                  onClick={() => {
                    setReviewModalOpen(false);
                    navigate("/transactions");
                  }}
                >
                  Close
                </button>
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />
    </div>
  );
};

export default Withdraw;
