import React, { useEffect, useState } from "react";
import TopBar from "../components/TopBar";
import { useSelector } from "react-redux";
import axios from "axios";
import Preloader from "../components/Preloader";

const Activity = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [activities, setActivities] = useState([]);
  const user = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    const token = localStorage.getItem("mpi");

    const getActivity = async () => {
      const { data } = await axios.get(
        `https://api.securedcompoundinterest.com/api/activity/${user._id}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setActivities(data);
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    };

    getActivity();
  }, [user._id]);

  return (
    <div className="page-user">
      {pageLoading && <Preloader />}
      <TopBar />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 main-content">
              <div className="card content-area">
                <div className="card-innr">
                  <div className="card-head">
                    <h4 className="card-title">Activity</h4>
                  </div>
                  <div className="card-text">
                    <p>Here is your recent activities log</p>
                  </div>
                  <div className="gaps-1-5x" />
                  <table
                    className="data-table dt-init activity-table"
                    data-items={10}
                  >
                    <thead>
                      <tr className="data-item data-head">
                        <th className="data-col activity-time">
                          <span>Date/Time</span>
                        </th>
                        <th className="data-col activity-device">
                          <span>Device</span>
                        </th>
                        <th className="data-col activity-browser">
                          <span>Browser</span>
                        </th>
                        <th className="data-col activity-ip">
                          <span>IP</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {activities?.slice(0, 10)?.map((log) => (
                        <tr className="data-item" key={log._id}>
                          <td className="data-col activity-time">
                            {new Date(log.createdAt).toLocaleString()}
                          </td>
                          <td className="data-col activity-device">{log.os}</td>
                          <td className="data-col activity-browser">
                            {log.browser}
                          </td>
                          <td className="data-col activity-ip">{log.ip}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activity;
