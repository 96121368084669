import preloader from "../assets/logo.png";

const Preloader = () => {
  return (
    <div className="preloader">
      <img src={preloader} alt="preloader" style={{ width: "200px" }} />
    </div>
  );
};

export default Preloader;
